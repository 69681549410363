import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'

const PrivacyPage = () => (
  <Layout>
  <div style={{backgroundColor: '#fff', padding: '2em'}}>
  <h2>Privacy Policy</h2>
<h5>What personal information do we collect from the people that visit our blog, website, or app?</h5>
<p>When ordering or registering on our site, you may be asked to enter your name, email address or other details to help you with your experience.</p>
<h5>When do we collect information?</h5>
<p>We collect information from you when you register on our site, place an order, fill out a form, open a support ticket or enter information on our site.</p>
<h5>How do we use your information?</h5>
<p>We may use the information we collect from you when you register, make a purchase, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
<ul>
<li>To personalize your experience and to allow us to deliver the type of content and product offerings in which you are most interested.</li>
<li>To improve our website in order to better serve you.</li>
<li>To allow us to better service you in responding to your customer service requests.</li>
<li>To administer a contest, promotion, survey or other site feature.</li>
<li>To quickly process your transactions.</li>
<li>To ask for ratings and reviews of services or products</li>
<li>To follow up with you after correspondence (live chat, email or phone inquiries)</li>
</ul>
<h5>How do we protect your information?</h5>
<p>An external PCI compliant payment gateway handles all CC transactions.</p>
<p>Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive information you supply is encrypted via Secure Socket Layer (SSL) technology.</p>
<p>We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.</p>
<p>All transactions are processed through a gateway provider and are not stored or processed on our servers.</p>
<h5>Do we use &#8216;cookies&#8217;?</h5>
<p>Yes. Cookies are small pieces of data that a site or its service provider transfers to your computer (if you allow) that enables the site&#8217;s or service provider&#8217;s systems to recognize your browser and capture and remember certain information. We use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</p>
<p>We use cookies to compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</p>
<p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser&#8217;s Help Menu to learn the correct way to modify your cookies.</p>
<p>If you turn cookies off, the website will continue to function properly.</p>
<h5>Third-party disclosure</h5>
<p>We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others&#8217; rights, property or safety.</p>
<h5>Third-party links</h5>
<p>We do not include or offer third-party products or services on our website.</p>
<h5>Google</h5>
<p>We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions as they relate to our website.</p>
<h5>According to the California Online Privacy Protection Act, we agree to the following</h5>
<ul>
<li>Users can visit our site anonymously.</li>
<li>We will link to this privacy policy on our home page.</li>
<li>Our Privacy Policy link includes the word &#8216;Privacy&#8217; and can easily be found on the page specified above.</li>
<li>You will be notified of any Privacy Policy changes on our Privacy Policy Page</li>
<li>You can change your personal information by logging in to your account</li>
</ul>
<h5>COPPA (Children Online Privacy Protection Act)</h5>
<p>We do not specifically market to children under the age of 13 years old.</p>
<h5>Fair Information Practices</h5>
<p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>
<p>Should a data breach occur, we will notify you via in-site notification within 7 business days.</p>
  </div>
  </Layout>
)

export default PrivacyPage
